export const contactsData = {
    email: 'nayanchabhadiya123@gmail.com',
    phone: '+917048158322',
    address: 'Surat,Gujarat,India ',
    github: 'https://github.com/NayanChabhadiya',
    facebook: 'https://fb.com/nayan.chabhadiya.1',
    linkedIn: 'https://linkedin.com/in/nayan-chabhadiya-68a8b3247',
    twitter: 'https://twitter.com/nayan1734',
    instagram: 'https://instagram.com/mr_.chabhadiya',
    stackOverflow: 'https://stackoverflow.com/users/21091812/nayan-chabhadiya',
    devUsername: "nayanchabhadiya2101",
}