export const educationData = [
    {
        id: 1,
        institution: 'J.P.Dawer Institute of Information Science and Technology - BSc (IT) & MSc (ICT) - VNSGU',
        course: 'Master Degree',
        startYear: '2022',
        endYear: 'Present'
    },
    {
        id: 2,
        institution: 'Sdj International College',
        course: 'Bachelor Degree',
        startYear: '2018',
        endYear: '2021'
    },
    {
        id: 3,
        institution: 'Rision Modern School',
        course: 'Higher Secondary',
        startYear: '2016',
        endYear: '2018'
    },
    {
        id: 4,
        institution: 'Hare Krishna Vidhyalaya',
        course: 'Secondary School',
        startYear: '2008',
        endYear: '2016'
    },
]