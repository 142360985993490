/* eslint-disable */
import { contactsImage } from './images'

export const theLightTheme = {
    type: 'light',
    primary: '#1D9BF0',
    quaternary: '#EFF3F4',
    quaternaryLight: '#F7F9F9',
    secondary: '#ffffff',
    tertiary: '#0D3C61',
    buttonColor: '#0D3C61',
    ptagcolor: '#0D3C61',
    contactsimg: contactsImage
}

// export const theLightTheme = {
//     type: 'light',
//     primary: '#1D9BF0',
//     quaternary: '#EFF3F4',
//     quaternaryLight: '#F7F9F9',
//     secondary: '#ffffff',
//     tertiary: '#0f1419',
//     buttonColor: '#0F1419',
//     contactsimg: contactsImage
// }


export const theDarkTheme = {
    type: 'dark',
    primary: '#1D9BF0',
    secondary: '#0F0C41',
    quaternary: '#0C0B33',
    quaternaryLight: '#8b98a5',
    tertiary: '#EFF3F4',
    buttonColor: '#8B98A5',
    contactsimg: contactsImage
}
