export const headerData = {
  name: "NAYAN CHABHADIYA",
  title: "Hello! I am",
  desciption:
    "Hello! I am NAYAN CHABHADIYA, a professional and passionate programmer in my daily life. A quick learner with a self-learning attitude. I love to learn and explore new technologies and am Passionate about Problem Solving. ",
  image: "",
  imagebw: "",
  resumePdf:
    "https://drive.google.com/file/d/1x8vQ2wPcaX-ZdE-SCwcoOhf6vt5hGTTi/view?usp=drive_link",
};
