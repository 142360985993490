import html from "../assets/svg/skills/html.svg";
import css from "../assets/svg/skills/css.svg";
import js from "../assets/svg/skills/javascript.svg";
import react from "../assets/svg/skills/react.svg";
import nextjs from "../assets/svg/skills/nextJS.svg";
import nodejs from "../assets/svg/skills/nodejs.svg";
import angular from "../assets/svg/skills/angular.svg";
import docker from "../assets/svg/skills/docker.svg";
import azure from "../assets/svg/skills/azure.svg";
import microsoftOffice from "../assets/svg/skills/microsoftoffice.svg";
import bootstrap from "../assets/svg/skills/bootstrap.svg";
import c from "../assets/svg/skills/c.svg";
import cPlusPlus from "../assets/svg/skills/cplusplus.svg";
import cSharp from "../assets/svg/skills/csharp.svg";
import java from "../assets/svg/skills/java.svg";
import php from "../assets/svg/skills/php.svg";
import git from "../assets/svg/skills/git.svg";
import canva from "../assets/svg/skills/canva.svg";
import ubuntu from "../assets/svg/skills/ubuntu.svg";
import mongodb from "../assets/svg/skills/mongoDB.svg";
import tailwind from "../assets/svg/skills/tailwind.svg";
import vitejs from "../assets/svg/skills/vitejs.svg";
import mysql from "../assets/svg/skills/mysql.svg";
import postgresql from "../assets/svg/skills/postgresql.svg";
import aws from "../assets/svg/skills/aws.svg";
import graphql from "../assets/svg/skills/graphql.svg";
import dotnet from "../assets/svg/skills/dotnet.svg";

export const skillsImage = (skill) => {
  const skillID = skill.toLowerCase();
  switch (skillID) {
    case "html":
      return html;
    case "css":
      return css;
    case "js":
      return js;
    case "react":
      return react;
    case "next js":
      return nextjs;
    case "node js":
      return nodejs;
    case "angular":
      return angular;
    case "docker":
      return docker;
    case "azure":
      return azure;
    case "microsoft office":
      return microsoftOffice;
    case "bootstrap":
      return bootstrap;
    case "c":
      return c;
    case "c++":
      return cPlusPlus;
    case "c#":
      return cSharp;
    case "java":
      return java;
    case "php":
      return php;
    case "git":
      return git;
    case "canva":
      return canva;
    case "ubuntu":
      return ubuntu;
    case "mongodb":
      return mongodb;
    case "tailwind":
      return tailwind;
    case "vitejs":
      return vitejs;
    case "mysql":
      return mysql;
    case "postgresql":
      return postgresql;
    case "aws":
      return aws;
    case "graphql":
      return graphql;
    case "dotnet":
      return dotnet;
    default:
      break;
  }
};
