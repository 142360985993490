// import eight from '../assets/svg/projects/eight.svg'
// import seven from '../assets/svg/projects/seven.svg'
// import six from '../assets/svg/projects/six.svg'
import dailydava from '../assets/svg/projects/Dailydava.png';
import hoconn from '../assets/svg/projects/Hoconn.png';
import acclook from '../assets/svg/projects/Acclook.png';
// import { dailydava } from 'src/assets/svg/projects/dailydava.png';


export const projectsData = [
    {
        id: 1,
        projectName: 'Daily Dava',
        projectDesc: 'This project about Simplifying the Development of medical store website',
        tags: ['React','NodeJs','MongoDB','Redux'],
        code: '',
        demo: 'https://dailydawa.in/',
        image: dailydava
    },
    {
        id: 2,
        projectName: 'Hoconn Fashion',
        projectDesc: 'This project is a Fashion Store.',
        tags: ['React', 'Graphql', 'Postgresql', 'paython'],
        code: '',
        demo: 'https://hoconn.netlify.app/',
        image: hoconn
    },
    {
        id: 3,
        projectName: 'Acclook',
        projectDesc: 'This project is a sell Gaming account',
        tags: ['Php', 'Html', 'Mysql', 'Bootstrap'],
        code: '',
        demo: 'https://acclook2023.000webhostapp.com/',
        image: acclook
    },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/